import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import UsersListTable from './UsersListTable';
import UsersFilter from './UsersFilter';
import AppPagination from '../../../components/AppPagination';

import { resetUsersState } from '../../../slices/usersSlice';
import { getUsersList } from '../../../actions/usersActions';

const defaultFilter = {
  name: '',
  city: '',
  email: '',
};

const Users = () => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.users.list);
  
  const [filter, setFilter] = useState({ ...defaultFilter });
  const [pagination, setPagination] = useState({ page: 1 });

  useEffect(() => {
    setPagination({ page: 1 });
    return () => dispatch(resetUsersState());
  }, []);

  useEffect(() => {
    const f = { page: pagination.page };

    filter.name.length > 0 ? f.name = filter.name : null;
    filter.city.length > 0 ? f.city = filter.city : null;
    filter.email.length > 0 ? f.email = filter.email : null;

    dispatch(getUsersList({ query: f }));
  }, [pagination]);

  const handleChange = e => setFilter({ ...filter, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    setPagination({ page: 1 });
  }

  const onClear = () => setFilter({ ...defaultFilter });

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () => setPagination({ page: Math.max(pagination.page - 1, 1) });

  return (
    <>
      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Usuarios</Typography>
      <UsersFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <UsersListTable users={users} />
      <AppPagination page={pagination.page} prev={prevPage} next={nextPage} />
    </>
  );
}

export default Users;
