import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginAdmin, logoutAdmin } from '../api/authApi';
import { clearToken, setToken } from '../slices/authSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const login = createAsyncThunk('auth/login', ({ email, password, callback }, { dispatch }) => {
  dispatch(startLoading());

  loginAdmin(email, password)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => {
    dispatch(setToken({ token: data.token }));
  })
  .then(() => callback())
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const logout = createAsyncThunk('auth/logout', ({ callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  logoutAdmin(token)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => {
    dispatch(clearToken());
  })
  .then(() => callback())
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
