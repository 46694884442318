import { TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';

const EmployeesListItem = props => {
  return (
    <TableRow>
      <TableCell><Link to={props._id}>{props.identityNumber}</Link></TableCell>
      <TableCell>{props.name} {props.firstLastName} {props.secondLastName}</TableCell>
      <TableCell>{props.city}</TableCell>
      <TableCell>{props.fixedPhoneNumber}</TableCell>
      <TableCell>{props.mobilePhoneNumber}</TableCell>
      <TableCell>{new Date(props.createdAt)?.toLocaleString()}</TableCell>
    </TableRow>
  );
}

export default EmployeesListItem;
