import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import EmployeesListItem from './EmployeesListItem';

const EmployeesListTable = props => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>Número de identidad</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>Ciudad</TableCell>
            <TableCell>Teléfono fijo</TableCell>
            <TableCell>Teléfono móvil</TableCell>
            <TableCell>Creado en</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.employees?.map(employee => <EmployeesListItem key={employee._id} {...employee} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EmployeesListTable;

