import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Grid, Typography } from '@mui/material';
import EmployeeDetailsCard from './EmployeeDetailsCard';
import EmployeeDetailsForm from './EmployeeDetailsForm';
import EmployeeDetailsEmployments from './EmployeeDetailsEmployments';

import { getCurrentEmployee } from '../../../../actions/employeesActions';
import { resetEmployeesState } from '../../../../slices/employeesSlice';

const styles = {
  title: {
    marginBottom: '1rem'
  },
  column: {
    paddingX: '1rem'
  }
}

const EmployeeDetails = () => {
  const params = useParams();

  const dispatch = useDispatch();

  const current = useSelector(state => state.employees.current);

  useEffect(() => {
    dispatch(getCurrentEmployee({ id: params.id }));
    return () => dispatch(resetEmployeesState())
  }, []);

  return (
    <>
      <Helmet>
        <title>Cristopher | Empleado {current.identityNumber ?? ''}</title>
      </Helmet>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Empleado {current.identityNumber ?? params.id}</Typography>
      <Grid container>
        <Grid item xs={12} md={4} sx={styles.column}>
          <EmployeeDetailsCard />
        </Grid>
        <Grid item xs={12} md={8} sx={styles.column}>
          <EmployeeDetailsForm />
        </Grid>
        <Grid item xs={12}>
          <EmployeeDetailsEmployments />
        </Grid>
      </Grid>
    </>
  );
}

export default EmployeeDetails;
