import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current: {},
  list: [],
  tentative: {},
}

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    setEmployeesList: (state, { payload }) => ({ ...state, list: [...payload.employees] }),
    setCurrentEmployee: (state, { payload }) => ({ ...state, current: { ...payload.employee } }),
    setTentativeEmployee: (state, { payload }) => ({ ...state, tentative: { ...payload.employee } }),
    resetEmployeesState: () => ({ list: [], current: { }, tentative: { } })
  }
});

export const { setEmployeesList, setCurrentEmployee, setTentativeEmployee, resetEmployeesState } = employeesSlice.actions;
export default employeesSlice.reducer;
