import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import EmployeeDetailsEmploymentsListTable from './EmployeeDetailsEmploymentsListTable';
import AppPagination from '../../../../components/AppPagination';

import { resetEmploymentsState } from '../../../../slices/employmentsSlice';
import { getEmploymentsList } from '../../../../actions/employmentsActions';

const EmployeeDetailsEmployments = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const employments = useSelector(state => state.employments.list);
  
  const [pagination, setPagination] = useState({ page: 1 });

  useEffect(() => {
    setPagination({ page: 1 });
    return () => dispatch(resetEmploymentsState());
  }, []);

  useEffect(() => {
    dispatch(getEmploymentsList({ query: { employee: params.id } }));
  }, [pagination]);

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () => setPagination({ page: Math.max(pagination.page - 1, 1) });

  return (
    <>
      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Empleos</Typography>
      <EmployeeDetailsEmploymentsListTable employments={employments} />
      <AppPagination page={pagination.page} prev={prevPage} next={nextPage} />
    </>
  );
}

export default EmployeeDetailsEmployments;
