import { useSelector } from 'react-redux';
import { Card, CardHeader, CardContent, Table, TableBody, TableRow, TableCell, Divider, Typography } from '@mui/material';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  media: {
    height: '25vh',
    cursor: 'pointer'
  },
  avatar: {
    width: '80px',
    height: '80px',
    cursor: 'pointer'
  },
  header: {
    color: '#000',
    backgroundColor: '#FFF',
    textAlign: 'start'
  },
  content: {
    paddingTop: '0'
  },
  actions: {
    mt: '1rem',
    display: 'flex',
    justifyContent: 'space-around'
  }
}

const UserDetailsCard = () => {
  const user = useSelector(state => state.users.current);

  return (
    <>
      <Card sx={styles.card}>
        <CardHeader sx={styles.header}
          title={user.name}
          subheader={user.nit}
        />
        <CardContent sx={styles.content}>
          <Typography sx={{ mb: '1rem' }} variant="h6">Datos de contacto</Typography>
          <Divider />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Dirección</TableCell>
                <TableCell>{user.address ?? 'Dirección'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ciudad</TableCell>
                <TableCell>{user.city ?? 'Ciudad'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Teléfono</TableCell>
                <TableCell>{user.phoneNumber ?? ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Correo</TableCell>
                <TableCell>{user.email ?? ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Contacto</TableCell>
                <TableCell>{user.contactName ?? ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Teléfono de contacto</TableCell>
                <TableCell>{user.contactPhoneNumber ?? ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Correo de contacto</TableCell>
                <TableCell>{user.contactEmail ?? ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Usuario desde</TableCell>
                <TableCell>{new Date(user.createdAt).toLocaleString()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
}

export default UserDetailsCard;
