import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Grid, MenuItem, TextField, Typography } from '@mui/material';

import { updateCurrentUser } from '../../../../actions/usersActions';
import UserDetailsCompany from './UserDetailsCompany';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
  title: {
    py: '1rem'
  },
  companyWrapper: {
    display: 'flex',
    alignItems: 'center'
  }
}

const UserDetailsForm = () => {
  const [user, setUser] = useState({
    _id: '',
    name: '',
    nit: '',
    address: '',
    city: '',
    taxType: '',
    email: '',
    phoneNumber: '',
    contactName: '',
    contactPhoneNumber: '',
    contactEmail: '',
    queries: '',
    status: '',
    business: false,
    companyRef: { },
  });

  const dispatch = useDispatch();
  const current = useSelector(state => state.users.current);

  const [openCompany, setOpenCompany] = useState(false);

  const handleOpenCompany = () => setOpenCompany(true);
  const handleCloseCompany = () => setOpenCompany(false);
    
  useEffect(() => setUser({ ...user, ...current }), [current]);

  const handleChange = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();

    const { name, address, city, phoneNumber, email, taxType, nit, contactName, contactPhoneNumber, contactEmail, queries, status, business } = user;

    dispatch(updateCurrentUser({
      id: user._id,
      user: { name, address, city, phoneNumber, email, taxType, nit, contactName, contactPhoneNumber, contactEmail, queries, status, business }
    }));
  }

  return (
    <Card sx={styles.card}>
      <CardHeader title="Datos del usuario" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Nombre" name="name" value={user.name} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Ciudad" name="city" value={user.city} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={12} sx={styles.inputWrapper}>
            <TextField label="Dirección" name="address" value={user.address} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Correo electrónico" name="email" value={user.email} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Teléfono móvil" name="phoneNumber" value={user.phoneNumber} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="NIT" name="nit" value={user.nit} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField select label="Tipo de contribuyente" name="taxType" value={user.taxType} onChange={handleChange}>
              <MenuItem value="Persona Natural">Persona Natural</MenuItem>
              <MenuItem value="Persona Jurídica">Persona Jurídica</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Consultas disponibles" name="queries" value={user.queries} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField select label="Estado" name="status" value={user.status} onChange={handleChange}>
              <MenuItem value="Activo">Activo</MenuItem>
              <MenuItem value="Desactivado">Desactivado</MenuItem>
              <MenuItem value="Bloqueado">Bloqueado</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField select label="Tipo de cuenta" name="business" value={user.business} onChange={handleChange}>
              <MenuItem value={false}>Regular</MenuItem>
              <MenuItem value={true}>Empresarial</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sx={styles.title}>
            <Typography variant="h6">Contacto</Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Nombre de contacto" name="contactName" value={user.contactName} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Correo de contacto" name="contactEmail" value={user.contactEmail} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Teléfono de contacto" name="contactEmail" value={user.contactEmail} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.title}>
            <Typography variant="h6">Empresa</Typography>
          </Grid>
          <Grid item xs={12} sx={styles.companyWrapper}>
            <Typography variant="h6" fontWeight="bold" sx={{ marginRight: '0.5rem' }}>{user.companyRef?.name ?? 'Sin empresa'}</Typography>
            <Button variant="text" onClick={handleOpenCompany}>Editar</Button>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button color="primary" onClick={handleSubmit}>Guardar</Button>
          </Grid>
        </Grid>
        <UserDetailsCompany open={openCompany} onClose={handleCloseCompany} />
      </CardContent>
    </Card>
  );
}

export default UserDetailsForm;
