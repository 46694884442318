import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Button, Grid, Typography } from '@mui/material';
import qs from 'query-string';

import EmployeeCreateDetails from './EmployeeCreateDetails';
import EmployeeCreateEmployment from './EmployeeCreateEmployment';
import EmployeeCreateFromCsv from './EmployeeCreateFromCsv';

import { createNewEmployee, findTentativeEmployee } from '../../../../actions/employeesActions';
import { createNewEmployment, findTentativeEmployment } from '../../../../actions/employmentsActions';
import { getCompaniesList } from '../../../../actions/companiesActions';
import { resetEmployeesState } from '../../../../slices/employeesSlice';
import { resetCompaniesState } from '../../../../slices/companiesSlice';

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem'
  },
  column: {
    paddingX: '1rem'
  }
}

const EmployeeCreate = () => {
  const [employeeRef, setEmployeeRef] = useState('');
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);

  const [employee, setEmployee] = useState({
    _id: '',
    identityNumber: '',
    identityNumberExpedition: new Date().toISOString().slice(0, 10),
    name: '',
    firstLastName: '',
    secondLastName: '',
    address: '',
    city: '',
    fixedPhoneNumber: '',
    mobilePhoneNumber: '',
    birthdate: new Date().toISOString().slice(0, 10),
    birthplace: '',
    gender: '',
    citizenship: '',
    public: true,
  });

  const [employment, setEmployment] = useState({
    _id: '',
    companyRef: '',
    admissionDate: new Date().toISOString().slice(0, 10),
    departureDate: new Date().toISOString().slice(0, 10),
    jobArea: '',
    firstCharge: '',
    lastCharge: '',
    supervisorName: '',
    supervisorPhone: '',
    supervisorEmail: '',
    departureReason: '',
  });

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const companies = useSelector(state => state.companies.list);
  const tEmployee = useSelector(state => state.employees.tentative);
  const tEmployment = useSelector(state => state.employments.tentative);

  useEffect(() => {
    dispatch(getCompaniesList({ results: 9999 }));
    return () => {
      dispatch(resetEmployeesState());
      dispatch(resetCompaniesState());
    }
  }, []);

  useEffect(() => {
    const query = qs.parse(location.search);
    if(query.empresa) setEmployment(prev => ({ ...prev, companyRef: query.empresa }));
  }, [location]);

  useEffect(() => {
    if(employee.identityNumber.length > 9) {
      dispatch(findTentativeEmployee({ query: { identityNumber: employee.identityNumber } }));
    }
  }, [employee.identityNumber]);

  useEffect(() => {
    setEmployeeRef(tEmployee._id);
    setEmployee(prev => ({
      ...prev,
      ...tEmployee,
      identityNumberExpedition: new Date(tEmployee?.identityNumberExpedition ?? new Date()).toISOString().slice(0, 10),
      birthdate: new Date(tEmployee?.birthdate ?? new Date()).toISOString().slice(0, 10),
    }));
  }, [tEmployee]);

  useEffect(() => {
    setEmployment(prev => ({
      ...prev,
      ...tEmployment,
      companyRef: tEmployment.companyRef?._id ?? prev.companyRef,
      employeeRef: tEmployee.employeeRef?._id ?? employeeRef,
      admissionDate: new Date(tEmployee?.tEmployment ?? new Date()).toISOString().slice(0, 10),
      departureDate: new Date(tEmployee?.tEmployment ?? new Date()).toISOString().slice(0, 10),
    }));
  }, [tEmployment]);

  useEffect(() => {
    if(employeeRef?.length > 0 && employment.companyRef?.length > 0) {
      dispatch(findTentativeEmployment({ query: { employee: employeeRef, company: employment.companyRef } }));
    }
  }, [employeeRef, employment.companyRef]);

  const handleChangeEmployee = e => setEmployee({ ...employee, [e.target.name]: e.target.value });
  const handleChangeEmployment = e => setEmployment({ ...employment, [e.target.name]: e.target.value });

  const handleOpenBatch = () => setOpen(true);
  const handleCloseBatch = () => setOpen(false);

  const handleEmployeeCreated = id => {
    setShow(true);
    setEmployeeRef(id);
  }

  const handleSaveEmployee = e => {
    e.preventDefault();
    dispatch(createNewEmployee({ employee, callback: handleEmployeeCreated }));
  }

  const handleSaveEmpoyment = e => {
    e.preventDefault();
    dispatch(createNewEmployment({ employment: { ...employment, employeeRef }, callback: () => navigate('/dashboard/empleados/' + employeeRef) }));
  }

  return (
    <>
      <Helmet>
        <title>Cristopher | Empleado nuevo</title>
      </Helmet>
      <Box sx={styles.title}>
        <Typography variant="h5" fontWeight="bold">Nuevo empleado</Typography>
        <Button onClick={handleOpenBatch}>Importar CSV</Button>
      </Box>
      <Grid container>
        <Grid item xs={12} sx={styles.column}>
          <EmployeeCreateDetails {...employee} onChange={handleChangeEmployee} onSubmit={handleSaveEmployee} />
        </Grid>
        <Grid item xs={12} sx={styles.column}>
          <EmployeeCreateEmployment {...employment} companies={companies} onChange={handleChangeEmployment} onSubmit={handleSaveEmpoyment} show={show} />
        </Grid>
      </Grid>
      <EmployeeCreateFromCsv open={open} onClose={handleCloseBatch} companies={companies} companyRef={employment.companyRef} />
    </>
  );
}

export default EmployeeCreate;
