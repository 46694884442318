import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Button, Typography } from '@mui/material';
import BundlesListTable from './BundlesListTable';
import BundlesFilter from './BundlesFilter';
import BundlesCreateDialog from './BundlesCreateDialog';
import AppPagination from '../../../components/AppPagination';

import { resetBundlesState } from '../../../slices/bundlesSlice';
import { getBundlesList } from '../../../actions/bundlesActions';

const defaultFilter = {
  name: '',
  queries: ''
};

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem'
  }
}

const Bundles = () => {
  const dispatch = useDispatch();
  const bundles = useSelector(state => state.bundles.list);
  
  const [filter, setFilter] = useState({ ...defaultFilter });
  const [pagination, setPagination] = useState({ page: 1 });

  const [openNew, setOpenNew] = useState(false);

  useEffect(() => {
    setPagination({ page: 1 });
    return () => dispatch(resetBundlesState());
  }, []);

  useEffect(() => {
    const f = { page: pagination.page };

    filter.name.length > 0 ? f.name = filter.name : null;
    filter.queries.length > 0 ? f.queries = filter.queries : null;

    dispatch(getBundlesList({ query: f }));
  }, [pagination]);

  const handleChange = e => setFilter({ ...filter, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    setPagination({ page: 1 });
  }

  const onClear = () => setFilter({ ...defaultFilter });

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () => setPagination({ page: Math.max(pagination.page - 1, 1) });

  const handleOpenNew = () => setOpenNew(true);
  const handleCloseNew = () => setOpenNew(false);

  return (
    <>
      <Helmet>
        <title>Cristopher | Paquetes</title>
      </Helmet>
      <Box sx={styles.title}>
        <Typography variant="h5" fontWeight="bold">Paquetes</Typography>
        <Button onClick={handleOpenNew}>Nuevo</Button>
      </Box>
      <BundlesFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <BundlesListTable bundles={bundles} />
      <AppPagination page={pagination.page} prev={prevPage} next={nextPage} />
      <BundlesCreateDialog open={openNew} onClose={handleCloseNew} />
    </>
  );
}

export default Bundles;
