import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current: { messages: [] },
  list: []
}

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setTicketsList: (state, { payload }) => ({ ...state, list: [...payload.tickets] }),
    setCurrentTicket: (state, { payload }) => ({ ...state, current: { ...payload.ticket } }),
    resetTicketsState: () => ({ list: [], current: { messages: [] } })
  }
});

export const { setTicketsList, setCurrentTicket, resetTicketsState } = ticketsSlice.actions;
export default ticketsSlice.reducer;
