import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (_, { payload }) => ({ token: payload.token }),
    clearToken: () => ({ token: null })
  }
});

export const { setToken, clearToken } = authSlice.actions;
export default authSlice.reducer;
