import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import { Close, UploadFile } from '@mui/icons-material';

import { uploadCurrentEmployeeNotice } from '../../../../actions/employeesActions';

const { REACT_APP_STORAGE_URL } = process.env;

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  item: {
    my: '0.5rem'
  },
  divider: {
    py: '0.5rem'
  }
}

const EmployeeDetailsNoticeDialog = props => {
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();

  const handleFileChange = e => setFile(e.target.files[0]);

  const handleClose = () => {
    setFile(null);
    props.onClose();
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(uploadCurrentEmployeeNotice({ id: props.id, notice: file }));
    props.onClose();
  }

  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth={false}>
      <DialogTitle sx={styles.title}>
        Acuerdos de tratamiento de datos personales
        <IconButton onClick={handleClose}><Close /></IconButton>
      </DialogTitle>
      <DialogContent sx={styles.content}>
        {props.notices.map(notice =>
          <Box key={notice} sx={styles.item}>
            <a href={`${REACT_APP_STORAGE_URL}${notice}`} target="_blank" rel="noreferrer">Acuerdo {notice.split('/').pop()}</a>
            <Divider sx={styles.divider} />
          </Box>  
        )}
      </DialogContent>
      <DialogActions>
        <IconButton component="label">
          <UploadFile /><input type="file" hidden={true} accept="application/pdf" onChange={handleFileChange} />
        </IconButton>
        <Button variant="text" disabled={!file} onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmployeeDetailsNoticeDialog;
