import { TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';

const UsersListItem = props => {
  return (
    <TableRow>
      <TableCell><Link to={props._id}>{props.name}</Link></TableCell>
      <TableCell>{props.nit}</TableCell>
      <TableCell>{props.email}</TableCell>
      <TableCell>{props.phoneNumber}</TableCell>
      <TableCell>{props.city}</TableCell>
      <TableCell>{props.contactName}</TableCell>
      <TableCell>{props.contactEmail}</TableCell>
      <TableCell>{props.contactPhoneNumber}</TableCell>
      <TableCell>{props.status}</TableCell>
      <TableCell>{new Date(props.createdAt)?.toLocaleString()}</TableCell>
    </TableRow>
  );
}

export default UsersListItem;
