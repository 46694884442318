const { REACT_APP_API_BASE_URL } = process.env;

export const getCompanies = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    results: 10,
    ...query
  }).toString();
  
  const request = new Request(REACT_APP_API_BASE_URL + '/companies?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getCompany = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/companies/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createCompany = (token, company) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/companies', {
    method: 'POST',
    body: JSON.stringify({ company }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updateCompany = (token, id, company) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/companies/' + id, {
    method: 'PUT',
    body: JSON.stringify({ company }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getCompanyStatistics = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(`${REACT_APP_API_BASE_URL}/companies/${id}/employees/statistics`, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const processCompanyEmployeesBatch = (token, id, file) => new Promise((resolve, reject) => {
  const formData = new FormData();
  formData.append('batch', file);

  const request = new Request(`${REACT_APP_API_BASE_URL}/companies/${id}/employees/batch`, {
    body: formData,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const processCompanyEmployeesBatchImages = (token, id, file) => new Promise((resolve, reject) => {
  const formData = new FormData();
  formData.append('file', file);

  const request = new Request(`${REACT_APP_API_BASE_URL}/companies/${id}/employees/batch/photos`, {
    body: formData,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const processCompanyEmployeesBatchNotices = (token, id, file) => new Promise((resolve, reject) => {
  const formData = new FormData();
  formData.append('file', file);

  const request = new Request(`${REACT_APP_API_BASE_URL}/companies/${id}/employees/batch/notices`, {
    body: formData,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
