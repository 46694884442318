import { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';

import { getCompaniesList } from '../../../../actions/companiesActions';
import { updateCurrentUser } from '../../../../actions/usersActions';

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  item: {
    my: '0.5rem'
  },
  link: {
    mt: '0.5rem',
    textAlign: 'center'
  },
  content: {
    minWidth: {
      xs: '300px',
      md: '500px'
    }
  }
}

const UserDetailsCompany = props => {
  const params = useParams();
  const dispatch = useDispatch();
  const companies = useSelector(state => state.companies.list);
  const [companyRef, setCompanyRef] = useState(null);

  useEffect(() => {
    if(props.open && options.length < 1) {
      dispatch(getCompaniesList({ query: { results: 9999 } }));
    }
  }, [props.open]);

  const options = useMemo(() => companies.map(company => ({ label: company.name, id: company._id })), [companies]);

  const handleChange = (_, newValue) => setCompanyRef(newValue?.id);

  const handleSubmit = e => {
    e?.preventDefault();
    dispatch(updateCurrentUser({ id: params.id, user: { companyRef } }));
    handleClose();
  }

  const handleClose = () => {
    props.onClose?.();
  }

  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth={false}>
    <DialogTitle sx={styles.title}>
      Empresa asociada
      <IconButton onClick={handleClose}><Close /></IconButton>
    </DialogTitle>
    <DialogContent sx={styles.content}>
      <Autocomplete
        options={options}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label="Empresa" />}
      />
      <Box sx={styles.link}>
        <Link to="/dashboard/empresas">Crear empresa</Link>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button variant="text" disabled={!companyRef} onClick={handleSubmit}>Guardar</Button>
    </DialogActions>
  </Dialog>
  );
}

export default UserDetailsCompany;
