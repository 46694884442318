import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Employees from './pages/Dashboard/Employees/Employees';
import EmployeeCreate from './pages/Dashboard/Employees/EmployeeCreate/EmployeeCreate';
import EmployeeDetails from './pages/Dashboard/Employees/EmployeeDetails/EmployeeDetails';
import Companies from './pages/Dashboard/Companies/Companies';
import CompanyDetails from './pages/Dashboard/Companies/CompanyDetails/CompanyDetails';
import Users from './pages/Dashboard/Users/Users';
import UserDetails from './pages/Dashboard/Users/UserDetails/UserDetails';
import Bundles from './pages/Dashboard/Bundles/Bundles';
import BundleDetails from './pages/Dashboard/Bundles/BundleDetails/BundleDetails';
import Tickets from './pages/Dashboard/Tickets/Tickets';
import TicketDetails from './pages/Dashboard/Tickets/TicketDetails/TicketDetails';

const Router = () => {
  const token = useSelector(state => state.auth.token);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={token ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path="/dashboard/*" element={token ? <Dashboard /> : <Navigate to="/login" />}>
          <Route index element={<Navigate to="empleados" />} />
          <Route path="empleados" element={<Employees />} />
          <Route path="empleados/cargar" element={<EmployeeCreate />} />
          <Route path="empleados/:id" element={<EmployeeDetails />} />
          <Route path="empresas" element={<Companies />} />
          <Route path="empresas/:id" element={<CompanyDetails />} />
          <Route path="usuarios" element={<Users />} />
          <Route path="usuarios/:id" element={<UserDetails />} />
          <Route path="paquetes" element={<Bundles />} />
          <Route path="paquetes/:id" element={<BundleDetails />} />
          <Route path="tickets" element={<Tickets />} />
          <Route path="tickets/:id" element={<TicketDetails />} />
          <Route path="*" element={<Navigate to="" />} />
        </Route>
        <Route path="*" element={token ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
