import { useState, useEffect } from 'react';
import { Box, Card, CardContent, Button, Typography, CardMedia } from '@mui/material';
import BundleDetailsImageCard from './BundleDetailsImageCard';

const { REACT_APP_STORAGE_URL } = process.env;

const styles = {
  card: {
    marginBottom: '1.5rem',
    maxWidth: '400px',
    mx: 'auto'
  },
  media: {
    height: '25vh',
    cursor: 'pointer'
  },
  actions: {
    mt: '1rem',
    display: 'flex',
    justifyContent: 'space-around'
  },
  text: {
    py: '1rem',
    textAlign: 'center'
  }
}

const BundleDetailsCard = props => {
  const [openPhoto, setOpenPhoto] = useState(false);
  const [timestamp, setTimestamp] = useState(new Date().toISOString());
    
  useEffect(() => {
    setTimestamp(new Date().toISOString());
  }, [props.imageUrl]);

  const handleOpenPhoto = () => setOpenPhoto(true);
  const handleClosePhoto = () => setOpenPhoto(false);

  const imageUrl = props.imageUrl ? `${REACT_APP_STORAGE_URL}${props.imageUrl}?timestamp=${timestamp}` : '/img/logo.png';

  return (
    <Box sx={styles.container}>
      <Card sx={styles.card}>
        <CardMedia image={imageUrl} onClick={handleOpenPhoto} sx={styles.media} />
        <CardContent>
          <Typography sx={{ mb: '0.5rem' }} variant="h5" fontWeight="bold" textAlign="center">Paquete {props.name}</Typography>
          <Typography sx={{ ...styles.text, py: 0, pt: '1rem' }} color="primary" fontWeight="bold" variant="h3">${props.price}</Typography>
          <Typography variant="body2" sx={{ ...styles.text, py: 0, pb: '1rem' }}>Incluye <b>{props.queries}</b> consultas</Typography>
          <Typography sx={styles.text}>{props.description}</Typography>
          <Box sx={{ textAlign: 'center' }}>
            <Button>Comprar</Button>
          </Box>
        </CardContent>
      </Card>
      <BundleDetailsImageCard title="Imagen del paquete" id={props?._id} src={imageUrl} open={openPhoto} onClose={handleClosePhoto} />
    </Box>
  );
}

export default BundleDetailsCard;
