import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { Menu } from '@mui/icons-material';
import AppDrawer from '../../components/AppDrawer';

const { REACT_APP_TITLE } = process.env;

const Dashboard = () => {
  const [open, setOpen] = useState(false);

  const openDrawer = () => setOpen(true);

  const closeDrawer = () => setOpen(false);

  return (
    <>
      <AppBar position="static" sx={{ padding: '0.5rem' }}>
        <Toolbar variant="dense">
          <IconButton edge="start" color="inherit" sx={{ mr: 1.5 }} onClick={openDrawer}>
            <Menu />
          </IconButton>
          <Typography variant="h5" color="inherit" component="div">
            {REACT_APP_TITLE}
          </Typography>
        </Toolbar>
      </AppBar>
      <AppDrawer open={open} onClose={closeDrawer} />
      <Box component="main" sx={{ padding: '1rem', display: 'flex', flexDirection: 'column' }}>
        <Outlet />
      </Box>
    </>
  );
}

export default Dashboard;
