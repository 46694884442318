import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle, Box, Button, DialogActions, Typography, ListItem, Divider, TextField } from '@mui/material';

import { createNewNegative, getNegativesList } from '../../../../actions/negativesActions';
import { resetNegativesState } from '../../../../slices/negativesSlice';

const styles = {
  container: {
    display: 'block',
    mb: '1rem',
    maxHeight: '50vh',
    overflowY: 'scroll'
  },
  content: {
    paddingY: 0,
    width: '75vw'
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  }
}

const EmployeeDetailsNegatives = props => {
  const dispatch = useDispatch();
  const negatives = useSelector(state => state.negatives.list);
  const [newNegative, setNewNegative] = useState({
    event: '',
    date: new Date().toISOString().slice(0, 10)
  });

  const params = useParams();

  useEffect(() => {
    dispatch(getNegativesList({ employeeId: params.id }));
    return () => dispatch(resetNegativesState());
  }, []);

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  const handleChange = e => setNewNegative(prev => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = e => {
    e?.preventDefault();

    dispatch(createNewNegative({ negative: newNegative, employeeId: props.id, callback: () => {
      dispatch(getNegativesList({ employeeId: params.id }));
      setNewNegative({ event: '', date: new Date().toISOString().slice(0, 10) })
    } }));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Eventos adversos de {props.name}</DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.container}>
          {negatives.map(negative =>
            <Box key={negative._id}>
              <ListItem>
                <Typography>{new Date(negative.date.slice(0, 19)).toLocaleDateString()} - {negative.event}</Typography>
              </ListItem>
              <Divider />
            </Box>
          )}
        </Box>
        <Box>
          <Typography variant="h6">Nuevo evento adverso</Typography>
          <TextField label="Evento" name="event" value={newNegative.event} onChange={handleChange} />
          <TextField type="date" label="Fecha" name="date" value={newNegative.date} onChange={handleChange} />
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button variant="text" onClick={handleSubmit}>Añadir</Button>
        <Button variant="text" onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmployeeDetailsNegatives;
