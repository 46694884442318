import { Box } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const styles = {
  carousel: {
    height: '60vh'
  },
  slide: {
    height: '60vh',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }
}

const EmployeeDetailsCarousel = ({ splideRef, ...props }) => {
  return (
    <Splide ref={splideRef} style={styles.carousel} options={{ rewind: true }}>
      {props.images.map(image =>
        <SplideSlide key={image}>
          <a href={image} target="_blank" rel="noreferrer">
            <Box sx={{ ...styles.slide, backgroundImage: `url(${image})` }} />
          </a>
        </SplideSlide>
      )}
    </Splide>
  );
}

export default EmployeeDetailsCarousel;
