import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import CompaniesListItem from './CompaniesListItem';

const CompaniesListTable = props => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>NIT</TableCell>
            <TableCell>Correo</TableCell>
            <TableCell>Teléfono</TableCell>
            <TableCell>Ciudad</TableCell>
            <TableCell>Contacto</TableCell>
            <TableCell>Correo contacto</TableCell>
            <TableCell>Teléfono contacto</TableCell>
            <TableCell>Creado en</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.companies?.map(company => <CompaniesListItem key={company._id} {...company} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CompaniesListTable;

