import { Button, Card, CardContent, CardHeader, Grid, MenuItem, TextField } from '@mui/material';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
}

const EmployeeCreateDetails = props => {
  return (
    <Card sx={styles.card}>
      <CardHeader title="Datos del empleado" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Número de identidad" name="identityNumber" value={props.identityNumber} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Expedición número de identidad" type="date" name="identityNumberExpedition" value={props.identityNumberExpedition} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Nombre" name="name" value={props.name} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Primer apellido" name="firstLastName" value={props.firstLastName} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Segundo apellido" name="secondLastName" value={props.secondLastName} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={7} sx={styles.inputWrapper}>
            <TextField label="Dirección" name="address" value={props.address} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={5} sx={styles.inputWrapper}>
            <TextField label="Ciudad" name="city" value={props.city} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Teléfono fijo" name="fixedPhoneNumber" value={props.fixedPhoneNumber} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Teléfono móvil" name="mobilePhoneNumber" value={props.mobilePhoneNumber} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Fecha de nacimiento" type="date" name="birthdate" value={props.birthdate} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Lugar de nacimiento" name="birthplace" value={props.birthplace} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Ciudadanía" name="citizenship" value={props.citizenship} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={3} sx={styles.inputWrapper}>
           <TextField select label="Género" name="gender" value={props.gender} onChange={props.onChange}>
              <MenuItem value="Masculino">Masculino</MenuItem>
              <MenuItem value="Femenino">Femenino</MenuItem>
              <MenuItem value="Otro">Otro</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={3} sx={styles.inputWrapper}>
           <TextField select label="Aceptó acuerdo" name="public" value={props.public} onChange={props.onChange}>
              <MenuItem value={true}>Sí</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button color="primary" onClick={props.onSubmit}>Guardar</Button>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default EmployeeCreateDetails;
