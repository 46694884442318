import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Assignment, Image, List } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography } from '@mui/material';

import { processCompanyEmployeesData } from '../../../../actions/companiesActions';

const styles = {
  container: {
    display: 'block',
    textAlign: 'center'
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  column: {
    px: '1rem'
  },
  submitButton: {
    mt: '2rem'
  }
}

const EmployeeCreateFromCsv = props => {
  const [files, setFiles] = useState({
    csv: null,
    images: null,
    notices: null
  });
  const [companyRef, setCompanyRef] = useState(props.companyRef);

  useEffect(() => {
    setCompanyRef(props.companyRef);
  }, [props.companyRef]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  const handleFileChange = e => {
    setFiles(prev => ({ ...prev, [e.target.name]: e.target.files[0] }));
  }

  const handleCompanyChange = e => {
    setCompanyRef(e.target.value);
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(processCompanyEmployeesData({ id: companyRef, files, callback: () => navigate('/dashboard/empresas/' + companyRef) }));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Importar desde CSV</DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.container}>
          <TextField select label="Empresa" name="companyRef" value={companyRef} onChange={handleCompanyChange}>
            {props.companies.map(company => <MenuItem key={company._id} value={company._id}>{company.name}</MenuItem>)}
          </TextField>
          <Grid container>
            <Grid item xs={12} md={4} sx={styles.column}>
              <Typography>Seleccione CSV de datos</Typography>
              <Button variant="outlined" color="primary" component="label" size="large" sx={styles.iconButton}>
                <input hidden accept="text/csv" type="file" name="csv" onChange={handleFileChange} />
                <List />
              </Button>
              <Typography variant="caption" fontWeight="bold" display="block">{files.csv?.name ?? 'Sin seleccionar'}</Typography> 
            </Grid>
            <Grid item xs={12} md={4} sx={styles.column}>
              <Typography>Seleccione ZIP de imágenes</Typography>
              <Button variant="outlined" color="primary" component="label" size="large" sx={styles.iconButton}>
                <input hidden accept="application/zip" type="file" name="images" onChange={handleFileChange} />
                <Image />
              </Button>
              <Typography variant="caption" fontWeight="bold" display="block">{files.images?.name ?? 'Sin seleccionar'}</Typography> 
            </Grid>
            <Grid item xs={12} md={4} sx={styles.column}>
              <Typography>Seleccione ZIP de avisos</Typography>
              <Button variant="outlined" color="primary" component="label" size="large" sx={styles.iconButton}>
                <input hidden accept="application/zip" type="file" name="notices" onChange={handleFileChange} />
                <Assignment />
              </Button>
              <Typography variant="caption" fontWeight="bold" display="block">{files.notice?.name ?? 'Sin seleccionar'}</Typography> 
            </Grid>
          </Grid>
          <Button onClick={handleSubmit} disabled={!files.csv || !files.images || !files.notices} sx={styles.submitButton}>Cargar</Button>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button variant="text" onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmployeeCreateFromCsv;
