import { Button, Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';


const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
}

const BundleDetailsForm = props => {
  return (
    <Card sx={styles.card}>
      <CardHeader title="Datos del paquete" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Nombre" name="name" value={props.name} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Descripción" name="description" value={props.description} onChange={props.onChange} multiline rows={5} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Precio" name="price" value={props.price} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Número de consultas" name="queries" value={props.queries} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button color="primary" onClick={props.onSubmit}>Guardar</Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default BundleDetailsForm;
