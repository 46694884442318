import { TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';

const EmploymentsListItem = props => {
  return (
    <TableRow>
      <TableCell><Link to={`/dashboard/empresas/${props.companyRef?._id}`}>{props.companyRef?.name}</Link></TableCell>
      <TableCell>{new Date(props.admissionDate).toLocaleDateString()}</TableCell>
      <TableCell>{new Date(props.departureDate).toLocaleDateString()}</TableCell>
      <TableCell>{props.departureReason}</TableCell>
      <TableCell>{props.firstCharge}</TableCell>
      <TableCell>{props.lastCharge}</TableCell>
      <TableCell>{props.jobArea}</TableCell>
      <TableCell>{props.supervisorName}</TableCell>
      <TableCell>{props.supervisorPhone}</TableCell>
      <TableCell>{props.supervisorEmail}</TableCell>
    </TableRow>
  );
}

export default EmploymentsListItem;
