import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Typography } from '@mui/material';
import TicketDetailsMessage from './TicketDetailsMessage';
import TicketDetailsNewMessage from './TicketDetailsNewMessage';

import { getCurrentTicket } from '../../../../actions/ticketsActions';
import { resetTicketsState } from '../../../../slices/ticketsSlice';

const styles = {
  title: {
    marginBottom: '1rem'
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1'
  }
}

const TicketDetails = () => {
  const params = useParams();

  const dispatch = useDispatch();

  const current = useSelector(state => state.tickets.current);

  useEffect(() => {
    dispatch(getCurrentTicket({ id: params.id }));
    return () => dispatch(resetTicketsState())
  }, []);

  return (
    <>
      <Helmet>
        <title>Cristopher | Ticket {current.subject ?? ''}</title>
      </Helmet>
      <Box sx={styles.box}>
        <Typography variant="h5" sx={styles.title} fontWeight="bold">Ticket: {current.subject ?? params.id}</Typography>
        <Container sx={{ flex: 1, overflowY: 'scroll', maxHeight: { xs: '70vh', md: '75vh' } }}>
          <Grid container>
            {current.messages?.map?.(message => <TicketDetailsMessage key={message._id} {...message} />)}
          </Grid>
        </Container>
      </Box>
      <TicketDetailsNewMessage />
    </>
  );
}

export default TicketDetails;
