import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close, AddPhotoAlternate } from '@mui/icons-material';
import EmployeeDetailsCarousel from './EmployeeDetailsCarousel';

import { uploadCurrentEmployeePhoto } from '../../../../actions/employeesActions';

const styles = {
  img: {
    minWidth: '250px',
    minHeight: '250px'
  },
  content: {
    padding: 0,
    width: '60vh'
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const EmployeeDetailsImageCard = props => {
  const [imageFile, setImageFile] = useState(null);
  const [photos, setPhotos] = useState([]);
  const splideRef = useRef();

  const dispatch = useDispatch();

  const handleImageChange = e => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = e => {
      setImageFile({ file, src: e.target.result });
      setPhotos(prev => [e.target.result, ...prev]);
    };

    reader.readAsDataURL(file);
  }

  const handleClose = () => {
    setImageFile(null);
    props.onClose();
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(uploadCurrentEmployeePhoto({ id: props.id, photo: imageFile.file }));
    props.onClose();
  }

  useEffect(() => {
    !imageFile ? setPhotos(props.photos ?? []) : null;
  }, [imageFile]);

  useEffect(() => {
    setPhotos(props.photos);
  }, [props.photos]);

  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth={false}>
      <DialogTitle sx={styles.title}>
        Fotos del Empleado
        <IconButton onClick={handleClose}><Close /></IconButton>
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <EmployeeDetailsCarousel images={photos} splideRef={splideRef} /> 
      </DialogContent>
      <DialogActions>
        <IconButton component="label">
          <AddPhotoAlternate />
          <input type="file" hidden={true} accept="image/*" onChange={handleImageChange} />
        </IconButton>
        <Button variant="text" disabled={!imageFile} onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmployeeDetailsImageCard;

