import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import UsersListItem from './UsersListItem';

const UsersListTable = props => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>NIT</TableCell>
            <TableCell>Correo</TableCell>
            <TableCell>Teléfono</TableCell>
            <TableCell>Ciudad</TableCell>
            <TableCell>Contacto</TableCell>
            <TableCell>Correo contacto</TableCell>
            <TableCell>Teléfono contacto</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Desde</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.users?.map(user => <UsersListItem key={user._id} {...user} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UsersListTable;

