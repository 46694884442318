import { Box, Grid, Typography } from '@mui/material';

const styles = {
  container: {
    display: 'flex'
  },
  message: {
    p: '0.75rem',
    borderRadius: '10px',
    color: 'white',
    mb: '1rem',
    maxWidth: '50vw'
  }
}

const TicketDetailsMessage = props => {
  const isAdmin = props.senderType === 'Admin';
  const messageStyle = isAdmin
    ? { textAlign: 'end', backgroundColor: 'primary.main' }
    : { textAlign: 'start', backgroundColor: 'neutral.main' };

  return (
    <Grid item xs={12} sx={{ ...styles.container, justifyContent: isAdmin ? 'flex-end' : 'flex-start' }}>
      <Box sx={{ ...styles.message, ...messageStyle }}>
        <Typography>{props.message}</Typography>
        <Typography variant="caption">{new Date(props.createdAt).toLocaleString()}</Typography>
      </Box>
    </Grid>
  );
}

export default TicketDetailsMessage;
