import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Grid, Typography } from '@mui/material';
import CompanyDetailsCard from './CompanyDetailsCard';
import CompanyDetailsForm from './CompanyDetailsForm';
import CompanyDetailsEmployments from './CompanyDetailsEmployments';

import { getCurrentCompany, getCurrentCompanyStatistics } from '../../../../actions/companiesActions';
import { resetCompaniesState } from '../../../../slices/companiesSlice';

const styles = {
  title: {
    marginBottom: '1rem'
  },
  column: {
    paddingX: '1rem'
  }
}

const CompanyDetails = () => {
  const params = useParams();

  const dispatch = useDispatch();

  const current = useSelector(state => state.companies.current);

  useEffect(() => {
    dispatch(getCurrentCompany({ id: params.id }));
    dispatch(getCurrentCompanyStatistics({ id: params.id }));
    return () => dispatch(resetCompaniesState())
  }, []);

  return (
    <>
      <Helmet>
        <title>Cristopher | Empresa {current.name ?? ''}</title>
      </Helmet>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Empresa {current.name ?? params.id}</Typography>
      <Grid container>
        <Grid item xs={12} md={4} sx={styles.column}>
          <CompanyDetailsCard />
        </Grid>
        <Grid item xs={12} md={8} sx={styles.column}>
          <CompanyDetailsForm />
        </Grid>
        <Grid item xs={12}>
          <CompanyDetailsEmployments />
        </Grid>
      </Grid>
    </>
  );
}

export default CompanyDetails;
